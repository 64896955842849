import React from "react"
import { graphql, Link } from "gatsby"

import { StaticImage } from 'gatsby-plugin-image'

import '../../../../node_modules/slick-carousel/slick/slick.css'
import '../../../../node_modules/slick-carousel/slick/slick-theme.css'

import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import FeatureSide from '../../../components/feature-side'
import FeatureRelated from '../../../components/feature-related'


const Home = ({ data }) => {
  return (
    <Layout>
      <SEO
        pagetitle="定年退職を迎えたあなたへ！これからの人生を楽しむための名言"
        pagepath="/feature/03/"
      />
      <main>
        <div className="l-feature__mv">
          <div className="l-feature__bg">
            <StaticImage
							src="../../../assets/images/feature/retire01.jpg"
							alt="定年退職を迎えたあなたへ！これからの人生を楽しむための名言"
						/>
          </div>
          <div className="l-feature__above">
            <h3 className="l-feature__hdg">定年退職を迎えたあなたへ！これからの人生を楽しむための名言</h3>
          </div>
        </div>
        <div className="l-feature">
          <div className="l-wrap">
            <div className="l-feature__item">
              <div className="l-feature__box">

                <div className="l-feature__main">

                  <div className="l-feature__trunk">
                    <div className="l-feature__paragraph">
                      <h2 className="l-feature__lead">
                        <span>定年退職を迎えたあなた。</span>
                        <span>家族のために生活のために、身を粉にして働いてきたあなた。</span>
                        <span>
                          定年退職を迎えた今、人生の主役はあなた！<br />
                          セカンドライフをワクワクする毎日に変える珠玉の名言をご紹介します！
                        </span>
                      </h2>
                    </div>

                    <div className="l-feature__paragraph">
                      <div className="l-feature__wrap">
                        <div className="l-feature__num">01.</div>
                        <div className="l-feature__quote">
                          {data.post1.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>

                        <p>ジョージエリオットは、イギリスの作家。</p>
                        <p>同棲愛者の彼は、その生涯の中で2人の男性と付き合い、結婚しました。</p>
                        <p>当時は現代に比べて、<span className="marker-b">同姓愛の理解が社会的に進んでいなかった</span>ことでしょう。</p>
                        <p>状況下であっても、<span className="marker-b">自分の感性</span>に素直に従ったジョージ。</p>
                        <p>そんな彼の言葉だからこそ、重みが感じられます。</p>
                        <p>あなたに心の底からやりたいことがあるなら今すぐやりましょう。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">02.</div>
                        <div className="l-feature__quote">
                          {data.post2.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>日本が世界に誇る企業、本田技研工業。</p>
                        <p>創業した本田宗一郎は伝説の経営者として有名ですね。</p>
                        <p>宗一郎は16歳の時に、自動車修理工場「アート商会」に丁稚奉公として入社します。</p>
                        <p>のれん分けが許されるまでの6年間、宗一郎はアート商会で技術を磨きます。</p>
                        <p>そして独立後、あの有名な本田技研工業の大躍進が始まるのです。</p>
                        <p><span className="f-l">総一郎にとってのアート商会での6年間、これが後の成功の礎になったはずです。</span></p>
                        <p>誰しもに必要な下積み期間。始めるなら今です。きっと宗一郎も同じことを言うでしょう。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">03.</div>
                        <div className="l-feature__quote">
                          {data.post3.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>劇作家で、演劇実験室「天井桟敷」の主宰であった寺山修司。</p>
                        <p>彼が書いた『書を捨てよ町へ出よう』は時代を超えて愛される名著です。</p>
                        <p>寺山は、<span className="f-l">多作で知られ、多数の文芸作品</span>を世に送り出しました。</p>
                        <p>そんな活動に生きたアーティストである寺山が語るこの言葉は、アーティストの本質である「作り続けること」そして輝かしい未来のみにフォーカスする大切さと勇気を与えてくれます。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">04.</div>
                        <div className="l-feature__quote">
                          {data.post4.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>「杞憂」という言葉があります。</p>
                        <p><span className="f-l">心配しないでいい事を心配すること。とりこし苦労。</span></p>
                        <p>という意味です。</p>
                        <p>私たちは<span className="f-l">杞憂病</span>にかかっているのではないでしょうか。</p>
                        <p>日本人は不安を感じやすい民族です。</p>
                        <p>だからこそ特に”杞憂”に行動を制限されてしまっているかもしれません。</p>
                        <p><span className="marker-b">何か新しいことを始めたいけど不安がよぎる。</span></p>
                        <p>そんな時はこのマークトウェインの言葉を思い出しましょう。</p>
                        <p>マークが言ってるから大丈夫です（笑）</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">05.</div>
                        <div className="l-feature__quote">
                          {data.post5.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>レゲエという音楽を世界的なジャンルに押し上げたボブ・マーリー。</p>
                        <p><span className="marker-b">印税をレコード外車とプロデューサーに全部持っていかれる。</span></p>
                        <p><span className="marker-b">路上生活を余儀なくされる。</span></p>
                        <p><span className="marker-b">政治構造に巻き込まれ狙撃される。</span></p>
                        <p>そんな困難にありながらも、理想を捨てなかったボブ。</p>
                        <p>一度は愛する母国を離れ、バハマに亡命するが帰国し伝説の『ワン・ラブ・ピース・コンサート』に出演。</p>
                        <p>対立していたジャマイカの与党と野党の党首をステージ上で握手させるという伝説をやってのけました。</p>
                        <p>あなたの心持ち1つで道は無限に広がっていることを教えてくれます。</p>
                        <p><span className="f-l">ボブの生き様から学びましょう。</span></p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">06.</div>
                        <div className="l-feature__quote">
                          {data.post6.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>ウィリアム・ジェームズは、アメリカの哲学者、心理学者。</p>
                        <p>他にもたくさんの名言を残したことで、世界的に有名です。</p>
                        <p>笑うから楽しくなると言う事は、<span className="f-l">現代の科学的にも正しい</span>ことが証明されつつあります。</p>
                        <p>笑顔に似た表情をつくると、ドーパミンが活性化します。</p>
                        <p>ドーパミンは、脳の｢快楽｣に関係した神経伝達物質なので、楽しくなくても笑顔をつくれば｢幸福物質｣が脳に出てくるのです。</p>
                        <p>ジェームスがどうやってこの事実に気づいたかどうかはわかりませんが、素晴らしい叡智が短い言葉に詰められた名言ですね。</p>
                        <p><span className="marker-b">気分が落ち込んでいる、不安で押しつぶされそうだ、人間関係がうまくいかない、そんな人こそ笑顔作ってみてはいかがですか？</span></p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">07.</div>
                        <div className="l-feature__quote">
                          {data.post7.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>インターネット通販最大手、アマゾンの創業者ジェフベゾス。</p>
                        <p>彼の名前を知らない人はいないでしょう。世界で初めて資産が2000億ドルを超えた人物です。</p>
                        <p>彼がアマゾンを創業するに当たったエピソードは非常に有名です。</p>
                        <p>当時彼は、ヘッジファンドに勤務しており、若干30歳の若さで上席副社長にまで登りつめた凄腕でした。</p>
                        <p>しかし彼はインターネットの流星を見越し、アマゾンの可能性にかけた。</p>
                        <p>その結果、アマゾンの成功があるのです。</p>
                        <p>特にその創業期は、激務で有名でした。</p>
                        <p>従業員には週６日10時間も働くことを要請していました。</p>
                        <p>こんな時間に対して人一倍厳しいベゾスだからこそ、<span className="f-l">つまらない人間と付き合う時間など皆無</span>だったのでしょう。</p>
                        <p>もしあなたが今、<span className="marker-b">つまらない人と付き合うことを余儀なくされているなら、見直しましょう。</span></p>
                        <p>ベゾスの名言を思い出してください。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">08.</div>
                        <div className="l-feature__quote">
                          {data.post8.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>マイクロソフト共同創業者、ビル・ゲイツ。</p>
                        <p>慈善活動家としても有名です。</p>
                        <p>実は2001年に、スティーブバルマーにCEOを譲ってからは、経営の第一線から退き、ビルアンドメリンダ財団での活動がメインです。</p>
                        <p>経営者として華々しい成功を収めたビル。その彼が最も注力しているのは<span className="f-l">慈善活動</span>です。</p>
                        <p>現代に生きる人の中で最も成功した人の中の1人。その彼が、<span className="marker-b">誰とも比較してはいけない</span>と言っているのは非常に興味深いです。</p>
                        <p><span className="f-l">比較する事は劣等感や嫉妬生むだけで、物事の本質的な解決</span>にはつながりません。</p>
                        <p>ビルはその経験と深い知恵からそのことが理解できていたのでしょう。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">09.</div>
                        <div className="l-feature__quote">
                          {data.post9.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>ロシアの偉大な小説家、ドストエフスキー。</p>
                        <p>彼には壮絶な経験があります。</p>
                        <p>彼は<span className="f-l">死刑執行の直前で命を救われた経験</span>があるのです。</p>
                        <p>神から与えられた命を大切に思ったドストエフスキーはその後、猛烈な勢いで作品を書き上げます。</p>
                        <p>こうして生まれたのが<span className="marker-y">『罪と罰』や『カラマーゾフの兄弟』</span>などの、時代を超えて愛される名作です。</p>
                        <p>死、という最大の不幸、恐怖を経験した彼だからこそ、<span className="f-l">幸福の本質を理解することができた</span>のでしょう。</p>
                        <p>この名言に関連する言葉としては、 日本の建築家、安藤忠雄も同じようなことを語っています。</p>
                        <p><span className="f-l">夢は達成するよりも、暗闇の中をもがき苦しみながら進んでいく過程にこそ、幸福がある</span>と著書で語っています。</p>
                        <p>夢を達成した後に燃え尽き、その後の人生をさまよってしまう成功者も数多く見られます。</p>
                        <p>ぜひ、その道中にこそ幸福があるのだと言うことを知り、あなたの活動の糧にしてください。</p>
                      </div>

                      <div className="l-feature__wrap">
                        <div className="l-feature__num">10.</div>
                        <div className="l-feature__quote">
                          {data.post10.edges.map(( {node }) => {
                            return (
                              <div className="info__item" key={node.id}>
                                <div className="info__item__details">
                                  <h3 className="info__item__ttl">
                                    {node.title}
                                    <span className="info__item__name">
                                      <Link to={`/person/${node.category.slug}`}>{node.category.name}</Link>
                                    </span>
                                  </h3>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                        <p>T型フォードを世に送り出し、車という概念を、庶民の乗り物にまで浸透することに成功したフォード。</p>
                        <p>彼の発言や、実績、功績を見ると、利己的な利益の追求よりも、<span className="marker-b">社会や世界の発展のために尽くす精神性が培われていた</span>ことがわかります。</p>
                        <p>この名言もそんな彼の精神性の高さを表す言葉です。</p>
                        <p>彼がビジネスをするのは、私財を肥やすことが目的ではありませんでした。</p>
                        <p><span className="f-l">社会の発展、そして人々の利便性の向上</span>が彼の行動の第一義だったのでしょう。</p>
                        <p>だからアーティストとして成功していく皆さんはフォードに倣い、利己的な欲望よりも利他、他の人を幸福にすることに注力してほしいなと思います。</p>
                      </div>

                    </div>
                  </div>
                  <div className="l-feature__trunk">
                    <FeatureRelated />
                  </div>
                </div>

                <div className="l-feature__data">
                  <FeatureSide data={data} />
                </div>

              </div>

            </div>

          </div>
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    post1: allContentfulBlogPost(
      filter: {id: {eq: "9c6c92ca-39c0-50c3-a2cb-c19674c3f205"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post2: allContentfulBlogPost(
      filter: {id: {eq: "4ef3397b-dc12-5789-ba2e-addd9c1b8850"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post3: allContentfulBlogPost(
      filter: {id: {eq: "bbdc575f-2baf-55ae-a80d-ba4230a0f9f9"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post4: allContentfulBlogPost(
      filter: {id: {eq: "4241b024-dfb1-566f-a326-6f7564b7feae"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post5: allContentfulBlogPost(
      filter: {id: {eq: "2f3a8729-a324-5f67-9f16-eb6f05ade74f"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post6: allContentfulBlogPost(
      filter: {id: {eq: "df357314-e67a-5820-a5db-e08325f31589"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post7: allContentfulBlogPost(
      filter: {id: {eq: "2042facd-8c50-5b2c-9f1b-1cbae6ba64a9"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post8: allContentfulBlogPost(
      filter: {id: {eq: "467283ac-c061-5678-9a3a-60e9532d626e"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post9: allContentfulBlogPost(
      filter: {id: {eq: "f59d9bf6-d77a-5e8a-b5ec-e221a1fe50d1"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    post10: allContentfulBlogPost(
      filter: {id: {eq: "1f1d7f11-639f-5b0e-91ae-2e8ba1575b4d"}}
    ) {
      edges {
        node {
          id
          slug
          title
          category {
            id
            slug
            name
          }
        }
      }
    }

    cate: allContentfulCategory {
      edges {
        node {
          informations {
            informations
          }
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
            description
          }
        }
      }
    }

    allCate: allContentfulCategory(
      sort: {fields: sort, order: DESC},
      limit: 20
    ) {
      edges {
        node {
          name
          slug
          id
          selfie {
            gatsbyImageData(layout: FULL_WIDTH)
            description
          }
        }
      }
    }

    tags: allContentfulTags {
      edges {
        node {
          title
          slug
          id
        }
      }
    }
  }
`

export default Home